import React, { ReactNode } from 'react';
import classes from './FormContainer.module.scss';
import clsx from 'clsx';
import Txt from 'components/atoms/Txt/Txt';
export interface Props {
  children: ReactNode;
  className?: string;
  isFullPage?: boolean;
  title?: string;
  subtitle?: string;
}

const FormContainer: React.FC<Props> = ({ children, className, isFullPage, title, subtitle }) => {
  return (
    <div
      className={clsx(classes.outer, isFullPage && classes.isFullPage, className, 'formContainer')}
    >
      <div className={classes.inner}>
        <div className={classes.titleContainer}>
          {title && (
            <Txt component="h2" className={classes.formTitle}>
              {title}
            </Txt>
          )}
          {subtitle && (
            <Txt component="p" className={classes.formSubTitle}>
              {subtitle}
            </Txt>
          )}
        </div>
        {children}
      </div>
    </div>
  );
};

export default FormContainer;
