import clsx from 'clsx';
import React from 'react';
import classes from './TextInput.module.scss';
import EyeOffIcon from './assets/eye-off.svg';
import EyeOnIcon from './assets/eye-on.svg';
import Txt from 'components/atoms/Txt/Txt';
import Input from './Input';

export interface TextInputProps {
  className?: string;
  inputClassName?: string;
  type?: string;
  id: string;
  ref?: React.RefObject<HTMLInputElement>;
  startAdornment?: React.ReactNode;
  isPassword?: boolean;
  labelPlaceholder?: boolean;
}
export type Props = TextInputProps & React.HTMLProps<HTMLInputElement>;

const TextInput = React.forwardRef<HTMLInputElement, Props>(function TextInput(
  { inputClassName, title, className, startAdornment, isPassword, labelPlaceholder, ...inputProps },
  ref,
) {
  const [isPasswordHidden, setIsPasswordHidden] = React.useState(true);
  const [inputValue, setInputValue] = React.useState('');

  function handleChange(event: React.FormEvent<HTMLInputElement>, inputProps: any) {
    setInputValue(event.currentTarget.value);
    inputProps.onChange(event);
  }

  const handleShowPasswordClick = () => {
    setIsPasswordHidden((isPasswordHidden) => !isPasswordHidden);
  };

  /* This label has been placed in its own constant because we will have a transition period of
   using 2 different label styles. One will have the label also used as a shrinking "placeholder",
  the other style consists of the label above the input element */
  const label = (
    <Txt component="label" className={classes.labelText} htmlFor={inputProps.id}>
      {title}
    </Txt>
  );

  return (
    <div
      className={clsx(
        classes.root,
        className,
        labelPlaceholder && classes.labelPlaceholder,
        labelPlaceholder && inputValue && classes.inputFilled,
      )}
    >
      {title && !labelPlaceholder && label}
      <div className={classes.inputContainer}>
        {startAdornment ? <div className={classes.adornment}>{startAdornment}</div> : null}

        <input
          type={isPassword && isPasswordHidden ? 'password' : 'text'}
          placeholder={title}
          {...inputProps}
          className={clsx(classes.input, inputClassName)}
          ref={ref}
          onChange={(event) => handleChange(event, inputProps)}
        />

        {title && labelPlaceholder && label}

        {isPassword && (
          <span className={classes.showPasswordBtn} onClick={handleShowPasswordClick}>
            {isPasswordHidden ? <EyeOffIcon /> : <EyeOnIcon />}
          </span>
        )}
      </div>
    </div>
  );
});
export default TextInput;
