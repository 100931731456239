import React, { ReactNode, ReactElement } from 'react';
import classes from './FullWidthCta.module.scss';
import A from 'components/atoms/A/A';
import PageContentWrapper from 'components/templates/Page/PageContentWrapper';
import Button from 'components/atoms/Button/Button';
import Txt from 'components/atoms/Txt/Txt';

export interface Props {
  title?: string | ReactElement;
  children?: ReactNode;
  ctaText?: string;
  ctaLink?: string;
}

const FullWidthCta: React.FC<Props> = ({ title, children, ctaText, ctaLink }) => {
  return (
    <div className={classes.wrapper}>
      <PageContentWrapper className={classes.root}>
        {title && <h3 className={classes.title}>{title}</h3>}
        {children && (
          <Txt component="p" className={classes.description}>
            {children}
          </Txt>
        )}
        {ctaText && ctaLink && (
          <Button
            component={A}
            to={ctaLink}
            type="submit"
            buttonSize="large"
            className={classes.ctaButton}
            color={'primary'}
            buttonType={'secondary'}
          >
            <Txt>{ctaText}</Txt>
          </Button>
        )}
      </PageContentWrapper>
    </div>
  );
};

export default FullWidthCta;
