import React, { ReactNode } from 'react';
import Hero from 'components/organisms/Hero/Hero';
import classes from './LandingPage.module.scss';
import LandingPageContent from 'components/organisms/LandingPageContent/LandingPageContent';
import LandingPageContentVanilla from 'components/organisms/LandingPageContent/LandingPageContentVanilla';
import HeroSignUpForm from 'components/organisms/Hero/HeroSignUpForm';
import Button from 'components/atoms/Button/Button';
import Txt from 'components/atoms/Txt/Txt';
import A from 'components/atoms/A/A';
import { SIGN_UP } from 'components/pages/pages';

export interface Props {
  leftColumnContent: ReactNode;
  isVanillaText: boolean;
}

const LandingPage: React.FC<Props> = ({ leftColumnContent, isVanillaText }) => {
  return (
    <main className="fadedPageBg">
      <Hero
        leftColumnContent={
          <>
            {leftColumnContent}
            <Button
              buttonSize="large"
              buttonType="primary"
              color="secondary"
              component={A}
              to={SIGN_UP.path}
              className={classes.signUpButton}
            >
              <Txt>Sign up now!</Txt>
            </Button>
          </>
        }
        rightColumnContent={
          <>
            <HeroSignUpForm />
          </>
        }
      />
      {isVanillaText ? <LandingPageContentVanilla /> : <LandingPageContent />}
    </main>
  );
};

export default LandingPage;
