import React from 'react';
import TextInput, { Props } from './TextInput';
import classes from './PasswordInput.module.scss';

const PasswordInput = React.forwardRef<HTMLInputElement, Props>(function PasswordInput(props, ref) {
  return (
    <TextInput
      ref={ref}
      labelPlaceholder
      {...props}
      isPassword
      autoComplete="off"
      className={classes.root}
    />
  );
});
export default PasswordInput;
