import clsx from 'clsx';
import React from 'react';
import classes from './AppStoreLinks.module.scss';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import A from '../A/A';
import AppStarRating from 'components/atoms/AppStarRating/AppStarRating';
import AppStoreBadge from './assets/AppStoreBadge.svg';
import AppStoreBadge_de from './assets/AppStoreBadge_de.svg';
import AppStoreBadge_fr from './assets/AppStoreBadge_fr.svg';
import PlayStoreBadge from './assets/PlayStoreBadge.svg';
import PlayStoreBadge_de from './assets/PlayStoreBadge_de.svg';
import PlayStoreBadge_fr from './assets/PlayStoreBadge_fr.svg';
import LocalisedSvg from 'components/atoms/LocalisedSvg/LocalisedSvg';

export interface Props {
  className?: string;
  filter?: 'ios' | 'android';
  hideRating?: boolean;
  showOnMobileOnly?: boolean;
}
const AppStoreLinks: React.FC<Props> = ({ filter, className, hideRating, showOnMobileOnly }) => {
  const { utmSource, utmMedium, utmCampaign } = useQueryStringParams();

  const utmString =
    utmSource && utmMedium && utmCampaign
      ? `&utm_source=${utmSource}&utm_medium=${utmMedium}&utm_campaign=${utmCampaign}`
      : '';
  const playStoreUrl = `https://play.google.com/store/apps/details?id=com.qmee.mobile${utmString}`;
  return (
    <div
      className={clsx(
        classes.appStoreLinks,
        className,
        showOnMobileOnly && classes.showOnMobileOnly,
      )}
    >
      {(!filter || filter === 'android') && (
        <A to={playStoreUrl} className={classes.appStoreLink}>
          <div className={classes.badgeContainer}>
            <LocalisedSvg
              localisedSvgs={{
                global: PlayStoreBadge,
                'de-de': PlayStoreBadge_de,
                'fr-fr': PlayStoreBadge_fr,
              }}
              className={classes.storeBadge}
            />
          </div>

          <AppStarRating rating={4.3} hideRating={hideRating} />
        </A>
      )}
      {(!filter || filter === 'ios') && (
        <A
          to="https://itunes.apple.com/app/qmee-surveys/id1107954624"
          className={classes.appStoreLink}
        >
          <div className={classes.badgeContainer}>
            <LocalisedSvg
              localisedSvgs={{
                global: AppStoreBadge,
                'de-de': AppStoreBadge_de,
                'fr-fr': AppStoreBadge_fr,
              }}
              className={classes.storeBadge}
            />
          </div>

          <AppStarRating rating={4.6} hideRating={hideRating} />
        </A>
      )}
    </div>
  );
};

export default AppStoreLinks;
