import React from 'react';
import classes from './Notice.module.scss';
import clsx from 'clsx';
import Txt from '../Txt/Txt';

export type MessageStatus = 'info' | 'success' | 'error' | 'warning';

export type Props<T extends React.ElementType = 'div'> = {
  status?: MessageStatus;
  message?: string;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  component?: T;
} & React.ComponentPropsWithoutRef<T>;

export default function Notice<T extends React.ElementType = 'div'>({
  status = 'info',
  children,
  className,
  message,
  component,
  title,
  ...rest
}: Props<T>) {
  const Root = (component || 'div') as React.ElementType;

  return (
    <Root className={clsx(classes.root, classes[status], className)} {...rest}>
      {title ? <Txt component="h4">{title}</Txt> : null}
      {message ? <Txt>{message}</Txt> : children}
    </Root>
  );
}
