import React from 'react';
import classes from './ErrorAlert.module.scss';
import Txt from '../Txt/Txt';
import Button from '../Button/Button';
import Overlay from '../Overlay/Overlay';

export interface Props {
  message: string;
  onClose: () => void;
}

const ErrorAlert: React.FC<Props> = ({ message, onClose }) => {
  return (
    <Overlay onClose={onClose}>
      <div className={classes.root}>
        <div className={classes.body}>
          <Txt component="p">{message}</Txt>
        </div>
        <Button className={classes.button} onClick={onClose}>
          OK
        </Button>
      </div>
    </Overlay>
  );
};

export default ErrorAlert;
