import React, { ReactElement } from 'react';
import classes from './AppStarRating.module.scss';
import clsx from 'clsx';

export interface Props {
  className?: string;
  maxRating?: number;
  rating: number;
  hideRating?: boolean;
}
const StarFull: React.FC<{}> = () => (
  <svg viewBox="0 0 24 24">
    <path
      fill="currentColor"
      d="M12,17.27L18.18,21L16.54,13.97L22,9.24L14.81,8.62L12,2L9.19,8.62L2,9.24L7.45,13.97L5.82,21L12,17.27Z"
    />
  </svg>
);
const StarHalf: React.FC<{}> = () => (
  <svg viewBox="0 0 24 24">
    <path fill="currentColor" d="M12,2L9.19,8.63L2,9.24l5.45,4.73L5.82,21L12,17.27" />
    <path fill="#f0f0f0" d="M12,17.27L18.18,21l-1.63-7.03L22,9.24l-7.19-0.61L12,2" />
  </svg>
);
const StarEmpty: React.FC<{}> = () => (
  <svg viewBox="0 0 24 24">
    <path
      fill="#f0f0f0"
      d="M22,9.24l-7.19-0.61L12,2L9.19,8.63L2,9.24l5.45,4.73L5.82,21L12,17.27L18.18,21l-1.64-7.03L22,9.24z"
    />
  </svg>
);

function StarRatingNew({ className, rating, maxRating = 5, hideRating }: Props): ReactElement {
  return (
    <div className={clsx(classes.root, className)}>
      {new Array(maxRating).fill(0).map((_, i) => {
        if (rating < i + 0.5) {
          // eslint-disable-next-line react/no-array-index-key
          return <StarEmpty key={i} />;
        }
        if (rating >= i + 1) {
          // eslint-disable-next-line react/no-array-index-key
          return <StarFull key={i} />;
        }
        // eslint-disable-next-line react/no-array-index-key
        return <StarHalf key={i} />;
      })}
      {!hideRating && <div className={classes.ratingLabel}>{rating}</div>}
    </div>
  );
}
export default StarRatingNew;
