import React from 'react';
import { useRouter } from 'next/router';

export interface Props {
  localisedSvgs: any;
  className: string;
}

const LocalisedSvg: React.FC<Props> = ({ localisedSvgs, className }: Props) => {
  const { locale } = useRouter();

  const SvgComponent =
    !locale || !localisedSvgs[locale] ? localisedSvgs.global : localisedSvgs[locale];

  return <SvgComponent className={className} />;
};

export default LocalisedSvg;
