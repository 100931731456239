import React from 'react';
import classes from './EntryForm.module.scss';
import SignUpForm from './SignUpForm';
import LoginForm from './LoginForm';
import FormContainer from 'components/atoms/FormContainer/FormContainer';

export interface Props {
  disabled?: boolean;
  className?: string;
  title?: string;
  subtitle?: string;
  disableRedirectAfterLogin?: boolean;
  isLoginForm?: boolean;
}

const EntryForm: React.FC<Props> = ({
  disabled,
  className,
  title,
  subtitle,
  disableRedirectAfterLogin,
  isLoginForm,
}) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const emailInputRef = React.useRef<HTMLInputElement>(null);
  const passwordInputRef = React.useRef<HTMLInputElement>(null);
  const emailAutofilled = false; //useDetectAutofill(emailInputRef);
  const passwordAutofilled = true; //useDetectAutofill(passwordInputRef);
  const formAutofilled = emailAutofilled && passwordAutofilled;

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setEmail(event.target.value);

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setPassword(event.target.value);

  // ===== TAKEN FROM OLD ENTRYFORM =====
  const formSettings = {
    submitDisabled: Boolean(((!email || !password) && !formAutofilled) || disabled),
  };

  const emailInputSettings = {
    onChange: handleEmailChange,
    type: 'text',
    id: 'entry-form-email',
    title: 'Email',
    value: email,
    placeholder: 'Email',
    autoComplete: 'off',
    disabled: !!disabled,
    ref: emailInputRef,
    className: classes.input,
  };
  const passwordInputSettings = {
    onChange: handlePasswordChange,
    id: 'entry-form-password',
    title: 'Password',
    value: password,
    placeholder: 'Password',
    disabled: !!disabled,
    ref: passwordInputRef,
    className: classes.input,
  };
  // ===== TAKEN FROM OLD ENTRYFORM =====

  return (
    <FormContainer className={className} isFullPage={true} title={title} subtitle={subtitle}>
      {isLoginForm ? (
        <LoginForm
          formSettings={formSettings}
          emailSettings={emailInputSettings}
          passwordSettings={passwordInputSettings}
          email={email}
          disableRedirectAfterLogin={disableRedirectAfterLogin}
          password={password}
        />
      ) : (
        <SignUpForm
          formSettings={formSettings}
          emailSettings={emailInputSettings}
          passwordSettings={passwordInputSettings}
          email={email}
          password={password}
        />
      )}
    </FormContainer>
  );
};
export default EntryForm;
