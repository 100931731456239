import clsx from 'clsx';
import React from 'react';
import classes from './SignUpForm.module.scss';

import { useRouter } from 'next/router';
import usePromiseState from 'components/../hooks/usePromiseState';
import A from 'components/atoms/A/A';
import ErrorAlert from 'components/atoms/ErrorAlert/ErrorAlert';
import PasswordInput from 'components/atoms/TextInput/PasswordInput';
import TextInput from 'components/atoms/TextInput/TextInput';
import Txt from 'components/atoms/Txt/Txt';
import Form from 'components/molecules/Form/Form';
import { DASHBOARD, REQUEST_PASSWORD_RESET, SIGN_UP } from 'components/pages/pages';
import { useLogin } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import { FormSettings, InputSettings } from './types';

export interface Props {
  formSettings: FormSettings;
  emailSettings: InputSettings;
  passwordSettings: InputSettings;
  email: string;
  disableRedirectAfterLogin?: boolean;
  password: string;
  title?: string;
  subtitle?: string;
}

const LoginFormNew: React.FC<Props> = ({
  formSettings,
  emailSettings,
  passwordSettings,
  email,
  password,
  disableRedirectAfterLogin,
  title,
  subtitle,
}) => {
  const login = useLogin();
  const [handleLogin, loginState] = usePromiseState(login);
  const [loginError, setLoginError] = React.useState('');
  const router = useRouter();
  const handleLoginPress = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleLogin(email, password)
      .then(() =>
        disableRedirectAfterLogin
          ? null
          : router.push(DASHBOARD.path, undefined, { shallow: true }),
      )
      .catch((err: any) =>
        setLoginError(
          err.loginError || 'Network error. Maybe you have tried too many times. Try again later.',
        ),
      );
  };

  return (
    <section className={classes.root}>
      {title && (
        <Txt component="h2" className={classes.formTitle}>
          {title}
        </Txt>
      )}
      {subtitle && (
        <Txt component="p" className={classes.formSubtitle}>
          {subtitle}
        </Txt>
      )}
      <Form
        submitDisabled={formSettings.submitDisabled || loginState.isLoading}
        onSubmit={handleLoginPress}
        submitName="Log In"
        submitButtonType="primary"
        submitButtonColor="secondary"
        submitButtonFullWidth={true}
      >
        <div className={classes.section}>
          <TextInput {...emailSettings} labelPlaceholder />
        </div>
        <div className={classes.section}>
          <PasswordInput {...passwordSettings} labelPlaceholder />
          <Txt
            component={A}
            className={clsx(classes.subscript, classes.forgotPassword)}
            to={REQUEST_PASSWORD_RESET.path}
          >
            Forgotten password?
          </Txt>
        </div>
      </Form>
      <Txt component="p" className={classes.lowerMessage}>
        Don&apos;t have a Qmee account?
      </Txt>
      <Txt component={A} to={SIGN_UP.path} className={classes.lowerLink}>
        Sign up for free
      </Txt>
      {loginError && <ErrorAlert message={loginError} onClose={() => setLoginError('')} />}
    </section>
  );
};
export default LoginFormNew;
