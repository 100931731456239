import React from 'react';

interface State {
  isLoading: boolean;
}
export default function usePromiseState<TInputs extends Array<any>, TResult>(
  callback: (...args: TInputs) => Promise<TResult>,
): [(...args: TInputs) => Promise<TResult>, State] {
  const [isLoading, setLoading] = React.useState(false);
  const statefulCallback = React.useCallback(
    (...args: TInputs) => {
      setLoading(true);
      return callback(...args)
        .then((res) => {
          setLoading(false);
          return res;
        })
        .catch((err) => {
          setLoading(false);
          throw err;
        });
    },
    [callback],
  );
  return [statefulCallback, { isLoading }];
}
