import React, { ReactNode } from 'react';
import classes from './SplitRowCta.module.scss';

export interface Props {
  children: ReactNode;
}

const SplitRowCta: React.FC<Props> = ({ children }) => {
  return <div className={classes.ctaContainer}>{children}</div>;
};

export default SplitRowCta;
