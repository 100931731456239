import React, { ReactNode } from 'react';
import classes from './PageRow.module.scss';
import Txt from '../Txt/Txt';
import clsx from 'clsx';
import PageContentWrapper from 'components/templates/Page/PageContentWrapper';

export interface Props {
  children: ReactNode;
  className?: string;
  rowTitle?: string;
  id?: string;
}

const PageRow: React.FC<Props> = ({ children, className, id, rowTitle }) => {
  const childElement = (
    <div className={clsx(classes.root, className)} id={id}>
      {children}
    </div>
  );

  return (
    <PageContentWrapper className={className} id={id}>
      {rowTitle && (
        <Txt component="h2" className={classes.rowTitle}>
          {rowTitle}
        </Txt>
      )}
      {childElement}
    </PageContentWrapper>
  );
};

export default PageRow;
