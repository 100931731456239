import React from 'react';
import Mailcheck from 'mailcheck';
import { useSignUp } from 'components/templates/AuthenticationProvider/AuthenticationProvider';
import Form from 'components/molecules/Form/Form';
import A from 'components/atoms/A/A';
import Button from 'components/atoms/Button/Button';
import TextInput from 'components/atoms/TextInput/TextInput';
import PasswordInput from 'components/atoms/TextInput/PasswordInput';
import usePromiseState from 'hooks/usePromiseState';
import { FormSettings, InputSettings } from './types';
import ErrorAlert from 'components/atoms/ErrorAlert/ErrorAlert';
import { PROTECT_YOUR_ACCOUNT, SCREENER_SURVEY, LOGIN } from 'components/pages/pages';
import {
  handleAcquisitionEvents,
  handleUserUpdated,
} from 'components/templates/TrackingProvider/trackingUtils';
import Txt from 'components/atoms/Txt/Txt';
import { useRouter } from 'next/router';
import classes from './SignUpForm.module.scss';
import Notice from 'components/atoms/Notice/Notice';
import Checkbox from 'components/atoms/Checkbox/Checkbox';
import dynamic from 'next/dynamic';

const DynamicTermsConditionsModal = dynamic(
  () => import('components/organisms/TermsAndConditionsModal/TermsAndConditionsModal'),
  {},
);

const DynamicModalPortal = dynamic(
  () => import('components/templates/ModalPortal/ModalPortal'),
  {},
);

export interface Props {
  formSettings: FormSettings;
  emailSettings: InputSettings;
  passwordSettings: InputSettings;
  email: string;
  password: string;
  title?: string;
  subtitle?: string;
}

interface MailcheckSuggestion {
  address: string;
  domain: string;
  full: string;
}

const SignUpForm: React.FC<Props> = ({
  formSettings,
  emailSettings,
  passwordSettings,
  email,
  password,
  title,
  subtitle,
}) => {
  const signUp = useSignUp();
  const [handleSignUp, signUpState] = usePromiseState(signUp);
  const [marketingConsent, setMarketingConsent] = React.useState(false);
  const [mailcheckSuggestion, setMailcheckSuggestion] = React.useState<MailcheckSuggestion | null>(
    null,
  );
  const router = useRouter();
  const [suggestionRejected, setSuggestionRejected] = React.useState(false);
  const [signUpError, setSignUpError] = React.useState<string | undefined>();
  const [isTermsConditionsVisible, setIsTermsConditionsVisible] = React.useState(false);

  function handleEmailBlur() {
    Mailcheck.run({
      email,
      suggested(suggestion: MailcheckSuggestion) {
        setMailcheckSuggestion(suggestion);
      },
      empty() {
        setMailcheckSuggestion(null);
      },
    });
  }

  const handleSignUpPress = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      const userData = await handleSignUp(email, password, marketingConsent);
      if (userData) {
        handleUserUpdated(userData.guid);
        handleAcquisitionEvents('sign-up', { userGuid: userData.guid });
      }
      router.push(SCREENER_SURVEY.path, undefined, { shallow: true });
    } catch (err: any) {
      setSignUpError(
        err.loginError || 'Network error. Maybe you have tried too many times. Try again later.',
      );
    }
  };
  function handleUseMailCheck() {
    if (emailSettings?.onChange && mailcheckSuggestion) {
      emailSettings?.onChange({
        target: { value: mailcheckSuggestion.full },
      } as React.ChangeEvent<HTMLInputElement>);
      setMailcheckSuggestion(null);
    }
  }

  const handleTermsClick = () => {
    setIsTermsConditionsVisible(true);
  };

  return (
    <section className={classes.root}>
      {title && (
        <Txt component="h2" className={classes.formTitle}>
          {title}
        </Txt>
      )}
      {subtitle && (
        <Txt component="p" className={classes.formSubtitle}>
          {subtitle}
        </Txt>
      )}
      <Form
        submitDisabled={formSettings.submitDisabled || signUpState.isLoading}
        onSubmit={handleSignUpPress}
        submitName="Sign me up!"
        submitButtonType="primary"
        submitButtonColor="secondary"
        submitButtonFullWidth
      >
        <div className={classes.section}>
          <TextInput {...emailSettings} onBlur={handleEmailBlur} labelPlaceholder />
          {mailcheckSuggestion && !suggestionRejected && (
            <Notice status={'warning'} className={classes.mailcheck} onClick={handleUseMailCheck}>
              <div>
                Did you mean {mailcheckSuggestion?.address}@
                <strong>{mailcheckSuggestion?.domain}</strong>?
              </div>
            </Notice>
          )}
        </div>
        <div className={classes.section}>
          <PasswordInput {...passwordSettings} />
          {signUpError !== undefined && (
            <p>
              <Txt component={A} to={PROTECT_YOUR_ACCOUNT.path}>
                How we help protect your account
              </Txt>
            </p>
          )}
        </div>
        <Checkbox
          id="marketing"
          label="Allow Qmee to send me emails regarding competitions, new features, reminders &amp; other
            promotional marketing messages."
          onChange={() => setMarketingConsent(!marketingConsent)}
          isChecked={marketingConsent}
          className={classes.checkbox}
        />

        <Txt component="p" className={classes.subscript}>
          By signing up I agree to the{' '}
          <Txt
            component={Button}
            className={classes.agreementButton}
            onClick={handleTermsClick}
            buttonType="text"
          >
            Terms &amp; Conditions
          </Txt>
        </Txt>
      </Form>
      <Txt component="p" className={classes.subscript}>
        Already have an account?{' '}
        <Txt component={A} to={LOGIN.path}>
          Log In
        </Txt>
      </Txt>
      {signUpError && <ErrorAlert message={signUpError} onClose={() => setSignUpError('')} />}

      {isTermsConditionsVisible && (
        <DynamicModalPortal>
          <DynamicTermsConditionsModal onClose={() => setIsTermsConditionsVisible(false)} />
        </DynamicModalPortal>
      )}
    </section>
  );
};
export default SignUpForm;
