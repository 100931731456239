import React from 'react';
import classes from './Checkbox.module.scss';
import clsx from 'clsx';
import Txt from 'components/atoms/Txt/Txt';

export interface Props {
  id: string;
  label: string;
  onChange: () => void;
  isChecked: boolean;
  className?: string;
}

const Checkbox: React.FC<Props> = ({ id, label, onChange, isChecked, className }) => {
  return (
    <label
      className={clsx(
        classes.checkboxLabel,
        classes.subscript,
        classes.marketing,
        classes.section,
        className,
      )}
      htmlFor="marketing"
    >
      <input
        type="checkbox"
        id="marketing"
        className={classes.checkboxInput}
        checked={isChecked}
        onChange={onChange}
      />
      <Txt>{label}</Txt>
    </label>
  );
};

export default Checkbox;
