import React from 'react';
import useIsSsr from '../../../hooks/useIsSsr';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
export interface Props {
  className?: string;
  withoutSSR?: boolean;
  force?: boolean;
  fill?: boolean;
  height?: number;
  children: React.ReactNode;
  fallback?: React.ReactNode;
}
export default function SuspenseWithIndicator({
  children,
  fallback,
  className,
  withoutSSR,
  height,
  force,
}: Props) {
  const isSsr = useIsSsr();
  const defaultedFallback = fallback || (
    <LoadingIndicator height={height || 16} className={className} />
  );
  if (force || (withoutSSR && isSsr)) {
    return <>{defaultedFallback}</>;
  }
  return <React.Suspense fallback={defaultedFallback}>{children}</React.Suspense>;
}
