import { useRouter } from 'next/router';

export default function useLocalisedImage() {
  const { locale } = useRouter();

  return (localisedImages: any) => {
    return !locale || locale === 'en-us' || !localisedImages[locale]
      ? localisedImages.global
      : localisedImages[locale];
  };
}
