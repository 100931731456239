import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import useRemoveQueryStringParams from 'hooks/useRemoveQueryStringParams';
import usePromiseState from '../../../hooks/usePromiseState';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import EntryForm from '../../organisms/EntryForm/EntryForm';
import { useLoginWithToken } from '../../templates/AuthenticationProvider/AuthenticationProvider';
import classes from './Login.module.scss';
import clsx from 'clsx';
import OinqSuccess from 'components/assets/oinq/oinq_success_shadow.svg';

export interface Props {
  disableRedirectAfterLogin?: boolean;
}

const Login: React.FC<Props> = () => {
  const params = useQueryStringParams();
  const loginWithToken = useLoginWithToken();
  const [handleLogin, loginState] = usePromiseState(loginWithToken);
  const usedLoginToken = React.useRef(false);
  const removeQueryStringParams = useRemoveQueryStringParams();

  useEffect(() => {
    if (params.login_token && !usedLoginToken.current) {
      usedLoginToken.current = true;
      if (params.mobile_app === 'true' && isMobile) {
        window.location.href = `qmee://login?login_token=${params.login_token}`;
      } else {
        // eslint-disable-next-line no-alert
        handleLogin(params.login_token).catch((err: any) => alert(err.loginError));
        removeQueryStringParams(['login_token', 'mobile_app']);
      }
    }
  }, [handleLogin, params, removeQueryStringParams]);
  return (
    <main className={clsx(classes.root, 'fadedPageBg')}>
      <div className={classes.formWidthContainer}>
        <EntryForm
          className={classes.formContainer}
          title="Log in to your account"
          isLoginForm={true}
          disableRedirectAfterLogin
        />
        <OinqSuccess className={classes.oinq} />
      </div>
    </main>
  );
};
export default Login;
