import React, { ReactNode } from 'react';
import classes from './SignUpMobileBanner.module.scss';
import { SIGN_UP } from 'components/pages/pages';
import Txt from 'components/atoms/Txt/Txt';
import A from 'components/atoms/A/A';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import OinqSuccess from 'components/assets/oinq/oinq_success.svg';
import OinqSuccessUsa from 'components/assets/oinq/oinq_success_usa.svg';
import OinqSuccessAus from 'components/assets/oinq/oinq_success_aus.svg';
import OinqSuccessCan from 'components/assets/oinq/oinq_success_can.svg';
import OinqSuccessFr from 'components/assets/oinq/oinq_success_fr.svg';
import OinqSuccessDe from 'components/assets/oinq/oinq_success_de.svg';
import LocalisedSvg from 'components/atoms/LocalisedSvg/LocalisedSvg';

export interface Props {}

const SignUpMobileBanner: React.FC<Props> = () => {
  const { locale } = useRouter();
  const longTitle = locale === 'fr-fr' || locale === 'de-de';

  return (
    <A to={SIGN_UP.path} className={clsx(classes.root, longTitle && classes.longTitle)}>
      <Txt component="p" className={classes.title}>
        Sign up and start earning
      </Txt>
      <Txt component="p" className={classes.description}>
        It&apos;s quick and easy
      </Txt>
      <LocalisedSvg
        localisedSvgs={{
          global: OinqSuccess,
          'en-us': OinqSuccessUsa,
          'en-au': OinqSuccessAus,
          'en-ca': OinqSuccessCan,
          'fr-fr': OinqSuccessFr,
          'de-de': OinqSuccessDe,
        }}
        className={classes.welcomeOinq}
      />
    </A>
  );
};

export default SignUpMobileBanner;
