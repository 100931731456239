import React from 'react';
import clsx from 'clsx';
import classes from './GamingPromotionBanner.module.scss';

import BannerDesktop from './assets/gaming-banner-desktop2.png';
import BannerMobile from './assets/gaming-banner-mobile2.png';
import Image from 'components/atoms/Image/Image';

import Banner from './Banner';
import { GAMING } from 'components/pages/pages';

export interface Props {}

const GamingPromotionBanner: React.FC<Props> = ({}) => {
  return (
    <Banner url={GAMING.path} bannerKey="gaming-promotion-2" closeClassName={classes.close}>
      <div className={classes.mobileImage}>
        {/* Next JS images are weird. Best to wrap them in a container */}
        <Image
          quality={100}
          src={BannerMobile}
          alt="Get more with gaming! Our gaming offers have higher payouts for a limited time. Now's the time to get paid to play!"
        />
      </div>
      <div className={classes.desktopImage}>
        {/* Next JS images are weird. Best to wrap them in a container */}
        <Image
          quality={100}
          src={BannerDesktop}
          alt="Get more with gaming! Our gaming offers have higher payouts for a limited time. Now's the time to get paid to play!"
        />
      </div>
    </Banner>
  );
};
export default GamingPromotionBanner;
