import React, { ReactElement } from 'react';
import TrustpilotLogo from './assets/trustpilot-logo.png';
import Image from 'components/atoms/Image/Image';
import classes from './TrustpilotWidget.module.scss';
import Txt from 'components/atoms/Txt/Txt';

declare global {
  interface Window {
    Trustpilot?: {
      loadFromElement: (ref: string | null, data: boolean) => void;
    };
  }
}

export interface TestimonialWidgetProps {
  title?: string | ReactElement;
}

const TestimonialWidget: React.FC<TestimonialWidgetProps> = ({ title }) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div className={classes.root}>
      {title && <h3 className={classes.title}>{title}</h3>}
      <div
        ref={ref} // We need a reference to this element to load the TrustBox in the effect.
        className="trustpilot-widget" // Renamed this to className.
        data-locale="en-US"
        data-template-id="53aa8912dec7e10d38f59f36"
        data-businessunit-id="57e6443f0000ff0005951fc3"
        data-style-height="140px"
        data-style-width="100%"
        data-theme="light"
        data-stars="1,2,3,4,5"
        data-tags="SelectedReview"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/qmee.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Txt component="h4">Find our reviews on:</Txt>
          <Image height={66} width={166} src={TrustpilotLogo} alt="truspilot-logo" layout="fixed" />
        </a>
      </div>
    </div>
  );
};

const SmallWidget = () => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = React.useRef(null);
  React.useEffect(() => {
    // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
    // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
    // When it is, it will automatically load the TrustBox.
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);
  return (
    <div className={classes.trustPilotSmallWidgetContainer}>
      <div
        ref={ref} // We need a reference to this element to load the TrustBox in the effect.
        className="trustpilot-widget" // Renamed this to className.
        data-locale="en-US"
        data-template-id="5406e65db0d04a09e042d5fc"
        data-businessunit-id="57e6443f0000ff0005951fc3"
        data-style-height="28px"
        data-style-width="100%"
        data-theme="light"
        data-stars="1,2,3,4,5"
        data-review-languages="en"
      >
        <a
          href="https://www.trustpilot.com/review/qmee.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className={classes.trustpilotLogo}>
            <Txt component="h4">Find out what our users think!</Txt>
            <Image
              height={66}
              width={166}
              src={TrustpilotLogo}
              alt="truspilot-logo"
              layout="fixed"
            />
          </div>
        </a>
      </div>
    </div>
  );
};

export interface Props {
  showSmallWidget?: boolean;
  title?: string | ReactElement;
}

const TrustBox: React.FC<Props> = ({ title, showSmallWidget }) => {
  if (showSmallWidget) {
    return <SmallWidget />;
  }
  return <TestimonialWidget title={title} />;
};
export default TrustBox;
