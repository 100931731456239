import LoadingIndicator from 'components/atoms/LoadingIndicator/LoadingIndicator';
import PageMetaData, { MetaData } from 'components/atoms/PageMetaData/PageMetaData';
import SuspenseWithIndicator from 'components/atoms/SuspenseWithIndicator/SuspendWithIndicator';
import GamingPromotionBanner from 'components/molecules/Banner/GamingPromotionBanner';
import ErrorBoundary from 'components/molecules/ErrorBoundary/ErrorBoundary';
import Login from 'components/pages/Login/Login';
import useIsSsr from 'hooks/useIsSsr';
import { useRouter } from 'next/router';
import React from 'react';
import { useIsLoggedIn } from '../AuthenticationProvider/AuthenticationProvider';
import classes from './Page.module.scss';
import PageContentWrapper from './PageContentWrapper';
import SignUpMobileBanner from 'components/organisms/SignUpMobileBanner/SignUpMobileBanner';

interface PropsWithMetaData {
  metaData: MetaData;
}
interface PropsWithoutMetaData {
  withNestedMetaData: true;
}
export type PageOptions = {
  requiresAuthentication?: boolean;
  skipSSR?: boolean;
  fullPage?: boolean;
  unlisted?: boolean;
  loggedInRedirect?: string;
  metaData?: MetaData;
  hasMobileSignUpBanner?: boolean;
} & (PropsWithMetaData | PropsWithoutMetaData);
export type Props = {
  children: React.ReactNode;
} & PageOptions;

// const GAMING_PROMOTION_PATHS = ['/surveys', '/dashboard', '/gaming', '/cashback', '/deals'];

function LoadingFallback() {
  return <LoadingIndicator className={classes.loadingFallback} width={64} />;
}

function Page({
  children,
  skipSSR,
  requiresAuthentication,
  fullPage,
  unlisted,
  loggedInRedirect,
  metaData,
  hasMobileSignUpBanner,
}: Props): JSX.Element {
  const isLoggedIn = useIsLoggedIn();
  const isSsr = useIsSsr();
  const router = useRouter();

  // const promotionEnabled = isLoggedIn && GAMING_PROMOTION_PATHS.includes(router.pathname);
  const promotionEnabled = false;

  React.useEffect(() => {
    if (isLoggedIn && loggedInRedirect) {
      router.push(loggedInRedirect, undefined, { shallow: true });
    }
  }, [isLoggedIn, router, loggedInRedirect]);

  let content = children;
  if ((requiresAuthentication || skipSSR) && isSsr) {
    // We don't wanna prerender any content if the content is not public
    content = <LoadingFallback />;
  } else if (!isLoggedIn && requiresAuthentication) {
    fullPage = true;
    content = <Login disableRedirectAfterLogin />;
  }

  return (
    <>
      <PageMetaData
        metaData={metaData}
        shouldIndexPage={Boolean(!unlisted && !requiresAuthentication)}
      />
      <ErrorBoundary>
        <SuspenseWithIndicator fallback={<LoadingFallback />}>
          {fullPage ? content : <PageContentWrapper topBottomPadding>{content}</PageContentWrapper>}
          {promotionEnabled && <GamingPromotionBanner />}
          {hasMobileSignUpBanner && <SignUpMobileBanner />}
        </SuspenseWithIndicator>
      </ErrorBoundary>
    </>
  );
}

const MemoizedPage = React.memo(Page);

export default MemoizedPage;
