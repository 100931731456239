import React, { ReactNode } from 'react';
import classes from './Hero.module.scss';
import PageRow from 'components/atoms/PageRow/PageRow';
import Column from 'components/atoms/Column/Column';

export interface Props {
  leftColumnContent?: ReactNode;
  rightColumnContent?: ReactNode;
}

const Hero: React.FC<Props> = ({ leftColumnContent, rightColumnContent }) => {
  return (
    <div className={classes.wrapper}>
      <PageRow className={classes.root}>
        <Column className={classes.leftColumn}>{leftColumnContent}</Column>
        <Column>{rightColumnContent}</Column>
      </PageRow>
    </div>
  );
};

export default Hero;
