import useTranslate from 'hooks/useTranslate';
import Head from 'next/head';
import React from 'react';
import { useRouter } from 'next/router';

export interface MetaData {
  title: string;
  description: string;
  imageUrl?: string;
  isTranslated?: true;
}
export interface Props {
  metaData?: MetaData;
  shouldIndexPage?: boolean;
  locales?: string[];
  shouldHideItunesBanner?: boolean;
}

const DEFAULT_LOCALE = 'en-us';
const PUBLIC_URL = process.env.PUBLIC_URL;

export default function PageMetaData({
  metaData,
  shouldIndexPage,
  locales,
  shouldHideItunesBanner,
}: Props) {
  const translate = useTranslate();

  const title = !metaData || metaData?.isTranslated ? metaData?.title : translate(metaData.title);
  const description =
    !metaData || metaData?.isTranslated ? metaData?.description : translate(metaData.description);
  const router = useRouter();
  const imageUrl = metaData?.imageUrl || `${PUBLIC_URL}/qmee-logo.png`;

  const pathWithoutQuery = router.asPath.replace(/[?].*/, '');
  const isValidLocale = !locales || !router.locale || locales.includes(router.locale);

  function localePath(locale: string) {
    return locale === DEFAULT_LOCALE
      ? `https://www.qmee.com${pathWithoutQuery}`
      : `https://www.qmee.com/${locale}${pathWithoutQuery}`;
  }

  const canonicalUrl = localePath(router.locale || DEFAULT_LOCALE);

  return (
    <>
      {metaData && (
        <Head>
          <title>{`${title} - Qmee.com`}</title>
          <meta name="description" content={description} />
          <link rel="canonical" href={canonicalUrl} />
          {locales ? (
            locales?.map((l) => <link key={l} rel="alternate" hrefLang={l} href={localePath(l)} />)
          ) : (
            <>
              {router.locales?.map((l) =>
                // The default locale is `default` not en-us
                l === router.defaultLocale ? null : (
                  <link key={l} rel="alternate" hrefLang={l} href={localePath(l)} />
                ),
              )}
              <link rel="alternate" hrefLang="x-default" href={localePath(DEFAULT_LOCALE)} />
            </>
          )}

          {/* Facebook Open Graph Meta Tags */}
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:title" content={`${title} - Qmee.com`} />
          <meta property="og:description" content={description} />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:site_name" content="Qmee.com" />
          <meta property="og:type" content="website" />
          <meta property="fb:app_id" content="123775991104422" />

          {/* Twitter Meta Tags */}
          <meta property="twitter:card" content="summary_large_image" />
          <meta property="twitter:site" content="@qmee" />
          <meta property="twitter:title" content={`${title} - Qmee.com`} />
          <meta property="twitter:description" content={description} />
          <meta property="twitter:image" content={imageUrl} />

          {!shouldHideItunesBanner && (
            <meta
              // eslint-disable-next-line react/no-unknown-property
              affiliate-data="qmeecomSiteBanner"
              content="app-id=1107954624"
              name="apple-itunes-app"
            />
          )}
        </Head>
      )}

      {shouldIndexPage !== undefined && (
        <Head>
          <meta name="robots" content={shouldIndexPage && isValidLocale ? 'index' : 'noindex'} />
        </Head>
      )}
    </>
  );
}
