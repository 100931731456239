import React, { ReactNode } from 'react';
import clsx from 'clsx';
import classes from './Column.module.scss';

export interface Props {
  children: ReactNode;
  className?: string;
}

const Column: React.FC<Props> = ({ children, className }) => {
  return <div className={clsx(classes.root, className)}>{children}</div>;
};

export default Column;
