import { useMutation } from 'react-relay';
import { graphql } from 'react-relay';
import { useFrontendEventTrackerMutation } from './__generated__/useFrontendEventTrackerMutation.graphql';

const frontendEventTrackerMutation = graphql`
  mutation useFrontendEventTrackerMutation($input: FrontendEventTriggeredInput!) {
    frontendEventTriggered(input: $input)
  }
`;

type FrontEndEvent =
  | 'share_button_clicked'
  | 'referral_promotion_page_loaded'
  | 'banner_clicked'
  | 'feedback_button_clicked'
  | 'review_dialog_closed'
  | 'username_reported';

export interface TrackingOptions {
  type: FrontEndEvent;
  value: string;
}

type TrackingHandler = (variables: TrackingOptions) => void;

export default function useFrontendEventTracker(): TrackingHandler {
  const [frontendEventTriggered] = useMutation<useFrontendEventTrackerMutation>(
    frontendEventTrackerMutation,
  );

  const handleTrackFrontendEvent = ({ type, value }: TrackingOptions) => {
    const input = {
      type,
      value,
      sourceApp: 'qmee-site',
    };

    frontendEventTriggered({ variables: { input } });
  };

  return handleTrackFrontendEvent;
}
